// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  MsalAzureRedirectUri: 'https://dev.alyeska.xom.cloud',
  apiEndpoint: 'https://alyeska-api-dev.azurewebsites.net/api',
  protectedResourceMap: [
    ['https://graph.microsoft.com', ['user.read.all']],
    [
      'https://alyeska-api-dev.azurewebsites.net/api',
      ['api://90fb0d92-f7b2-4f1f-a3e9-5793e2cf084e/general']
    ]
  ] as [string, string[]][]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
